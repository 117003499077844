// ./utils/get-stripejs.ts
import { stripeClient } from '@/lib/index';
import { Stripe, loadStripe } from '@stripe/stripe-js';

let stripePromise: Promise<Stripe | null>;
const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe(stripeClient);
  }
  return stripePromise;
};

export default getStripe;
