import * as React from 'react';
import { NextPage } from 'next';
import { Layout } from '../src/components/Layout/';
import { createLogger } from '@/modules/logging/logger';
import { EventProps } from '@/modules/data/types';
import { Db } from 'mongodb';
const connect = require('./api/db');
import { eventCollection } from './api/helpers';
import Sentry from '@/modules/sentry/init';
import { Cabo } from '@/components/Event/Cabo';

let logger = createLogger({
  fileLabel: '/pages/index',
});

interface HomeProps {
  eventsData?: EventProps;
}

export async function getStaticProps() {
  try {
    const db: Db = await connect();
    const eventsData = await db.collection(eventCollection).findOne({ slug: 'cabo' });
    return {
      props: {
        eventsData: JSON.parse(JSON.stringify(eventsData)),
      },
      // Next.js will attempt to re-generate the page:
      // - When a request comes in
      // - At most once every 10 seconds
      revalidate: 10, // In seconds
    };
  } catch (e) {
    logger.error(e);
    Sentry.captureException(e);
  }
}

const HomePage: NextPage<HomeProps> = ({ eventsData }) => {
  const site = {
    title: `Beyond Borders Cabo 2024`,
    header: 'Memorial Day Weekend ',
  };

  return (
    <Layout customSite data={site}>
      <Cabo event={eventsData} />
    </Layout>
  );
};

export default HomePage;
