import React, { useEffect } from 'react';
import { useState, useRef } from 'react';
import FadeIn from 'react-fade-in';
import Reward from 'react-rewards';
import {
  formatEventTime,
  stripeClient,
  isEventInFuture,
  formatDateTimeWithTimeZone,
  getTotalOrderRev,
  serverDomain,
  areAllTicketsFree,
  isEventNotPublished,
  stripeFee,
  formatPrice,
} from '@/lib/index';
import classnames from 'classnames';
import { Elements } from '@stripe/react-stripe-js';
import customId from 'r2-custom-id';
import axios from 'axios';
import { createLogger } from '@/modules/logging/logger';
import {
  EventProps,
  OrderProps,
  UserTicketProps,
  OfferProps,
  EventCartProps,
  TicketProps,
} from '@/modules/data/types/';
import { CheckoutFlow } from '@/lib/constants';
import styled from 'styled-components';
import classNames from 'classnames';
import Lottie from 'react-lottie';
import animationData from '@/static/animations/diced.json';
import Image from 'next/image';
import { purchaseEvent, trackEvent } from '@/modules/gtag';
import { SmsProps } from '@/modules/twilio';
import { useRouter } from 'next/router';
import getStripe from '@/modules/stripe';
import { Tooltip } from 'react-tooltip';
import { useAutoAnimate } from '@formkit/auto-animate/react';
import { Country } from 'react-phone-number-input';
import { trackAction } from '@/modules/amplitude/amplitudeBrowserClient';
import { AMPLITUDE_ACTIONS, AMPLITUDE_EVENTS } from '@/modules/amplitude/events';
import urlencode from 'urlencode';
const logger = createLogger({
  fileLabel: `components/Event/Event`,
});
import Modal from 'react-modal';
import { Carousel } from '@mantine/carousel';

interface EventViewProps {
  event: EventProps;
}

const ParentDiv = styled.div`
  > p > span {
    background-color: black !important;
  }
`;
const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const scrollTo = (ref) => {
  if (ref && ref.current /* + other conditions */) {
    ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }
};
export const Cabo: React.FunctionComponent<EventViewProps> = ({ event }) => {
  const [step, setStep] = useState<CheckoutFlow>(CheckoutFlow.EVENT_VIEW);
  const [cart, setCart] = useState<{
    [ticketName: string]: EventCartProps;
  }>({});
  const [order, setOrder] = useState<OrderProps>(null);
  const [orderId] = useState<string>(
    customId({
      eventId: event._id,
      orderDate: new Date(),
    }),
  );
  const [offers, setOffers] = useState<{
    [couponCode: string]: OfferProps;
  }>(event.offers);
  const [total, setTotal] = useState<number>(0);
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [zipCode, setZipCode] = useState<string>('');
  const [offerCode, setOfferCode] = useState<string>('');
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  // const [trackUrl, setTrackUrl] = useState<string>('');
  const [emailAddress, setEmailAddress] = useState<string>('');
  const [checkoutQuestions, setCheckoutQuestions] = useState<{}>('');
  // const [readMore, setReadMore] = useState<boolean>(false);
  const [showPasswordModal, setShowPasswordModal] = useState<boolean>(false);
  const stripePromise = getStripe();
  const ticketsRef = useRef(null);
  const [extraFields, setExtraFields] = useState<{ [field: string]: string }[]>([]);
  // const showReadMore =
  //   (parse(event.description) as Array<any>)?.filter((p) => typeof p !== 'string')?.length > 8 ? true : false;
  const [clientSecret, setClientSecret] = useState<string>(null);
  const [textTickets, setTextTickets] = useState<boolean>(false);
  const [waitlist, setWaitlist] = useState<boolean>(false);
  const [offerBased, setOfferBased] = useState<boolean>(false);
  const [autoAccept, setAutoAccept] = useState<boolean>(false);
  const [fetchStripe, setFetchStripe] = useState<boolean>(false);
  const [openShare, setOpenShare] = useState<boolean>(false);
  const [copied, setCopied] = useState(false);
  const [showPageLink, setShowPageLink] = useState(false);
  const [shareParent, enableAnimations] = useAutoAnimate({ easing: 'ease-out', duration: 300 });
  const myRef = useRef(null);
  const reward = useRef(null);
  const router = useRouter();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [localFees, setLocalFees] = useState<number>(0);

  const [selectedTicketName, setSelectedTicketName] = useState<string | null>(null);
  const [ticketTypes, setTicketTypes] = useState<{ [ticketName: string]: TicketProps }>({});
  const handleSelectedTicket = (ticketName: string) => {
    setSelectedTicketName(ticketName);
  };

  const [offerNotFound, setOfferNotFound] = useState<boolean>(false);
  const [localOfferCode, setLocalOfferCode] = useState<string>('');

  const executeScroll = () => myRef?.current?.scrollIntoView();
  useEffect(() => {
    if (total) {
      logger.info(createOrder({}));

      prepareCheckout();
    }
  }, [total]);

  useEffect(() => {
    if (event.password) {
      setShowPasswordModal(true);
    }
  }, [event.password]);

  useEffect(() => {
    import(`react-facebook-pixel`)
      .then((x) => x.default)
      .then((ReactPixel) => {
        if (event.slug === 'techsgiving') {
          logger.info('yerr');
          ReactPixel.init('378782229785589'); // facebookPixelId
          ReactPixel.pageView();
        }

        //  router.events.on(routeChangeComplete, () => {
        //    ReactPixel.pageView();
        //  });
      })
      .catch((e) => logger.error(e));
  }, []);

  const hasRefundPolicy = event.refunds;
  const hasLineUp = Object.keys(event.lineUp).length;
  const hasPhotos = event.photos.length;
  const confirmationHeader = () => {
    if (waitlist || event.approvalNeeded) {
      return `You are on the waitlist!`;
    } else if (order?.cleared) {
      return `Thanks for your order!`;
    }
    return `You've been placed on the waitlist`;
  };
  const confirmationSupportText = () => {
    if (waitlist) {
      return `Once a spot opens up make sure to move fast before they're gone!`;
    } else if (event.approvalNeeded) {
      return `Keep your eyes peeled – event organizers will review your RSVP soon. ${
        order.total
          ? `We'll initiate a hold that lasts for up to 7 days; your card will be charged only if your ${
              waitlist || event.approvalNeeded ? `order` : `offer`
            } is accepted.`
          : ``
      }`;
    } else if (order?.cleared) {
      return `We've sent a confirmation email with details to your email.`;
    }
    return `We will place a hold to reserve funds now for up to 7 days, but only charge your card if your offer is accepted.`;
  };

  const sendSms = async () => {
    const smsData: SmsProps = {
      to: [{ phoneNumber, firstName }],
      marketing: false,
      message: `Hey ${firstName}, ${event.name} is happening on ${formatDateTimeWithTimeZone(
        event.startDate,
        'short-no-year',
        event.location.timeZoneId,
      )} - Here are your tickets! 🎟️ ${serverDomain}/tix/${event._id}/order/${order._id}`,
      slack: { enable: false },
    };
    setTextTickets(true);
    trackEvent({ action: 'text_tix_order', category: 'event_page', label: 'Text your tickets' });
    await axios.post('/api/twilio', {
      smsData,
    });
  };
  const ShareText = `I'm headed to ${event?.name} on @whatsba https://www.whatstba.com/e/${event?.slug}`;
  const ShareUrl = `https://www.whatstba.com/e/${event?.slug}`;
  const hasDescription = () => {
    return event.description ===
      '<p><br /> <br/>Enter in some information and details about your event here...<br /><br/> <br /></p>'
      ? false
      : event.description === '<p>Enter in some information and details about your event here...</p>'
      ? false
      : event.description === '<p><br></p>'
      ? false
      : true;
  };

  const setMode = (step: number) => {
    setStep(step);
    if (step === CheckoutFlow.TICKET_SELECTION) {
      setClientSecret('');
      trackEvent({ action: 'show_tickets', category: 'event_page', label: 'Get your tickets' });
    }
    if (reward.current && step === CheckoutFlow.CONFIRMATION) {
      reward.current.rewardMe();
    }
  };

  const createOrder = (xtraFields) => {
    const { ticketTypes } = event;
    const tickets = [] as UserTicketProps[];
    let isOfferBasedTix = false;
    Object.keys(cart).forEach((curr) => {
      const tix = cart[curr];
      for (let i = 0; i < tix.quantity; i++) {
        const tempTix = ticketTypes[tix.ticketName];
        if (tempTix.ticketType === 'offer' && !tempTix.autoAcceptOffers) {
          isOfferBasedTix = true;
          setOfferBased(true);
        }
        order;
        tickets.push({
          ticketName: tempTix.ticketName,
          fee: cart[curr].fee,
          price: cart[curr].price,
          description: tempTix.description,
          guest: false,
          barCode: curr,
          orderId,
          eventId: event._id,
          checkedIn: null,
          checkInDate: null,
        });
      }
    });
    const newOrder: OrderProps = {
      emailAddress,
      firstName,
      updatedAt: new Date(),
      lastName,
      zipCode,
      eventId: event._id,
      currency: event.currency ?? 'USD',
      _id: orderId,
      token: null,
      guest: false,
      phoneNumber,
      extraFields: xtraFields,
      checkedIn: false,
      refunded: false,
      cancelled: false,
      cleared: (offerBased || isOfferBasedTix || event.approvalNeeded) && !autoAccept ? false : true,
      status: (offerBased || isOfferBasedTix || event.approvalNeeded) && !autoAccept ? 'pending' : 'copped',
      tickets,
      stripeFee: total ? parseFloat(stripeFee(total, event.currency).toFixed(2)) : 0,
      total,
      offerCode,
      offerBased,
      orderDate: new Date(),
      cart,
    };
    setOrder(newOrder);
    return newOrder;
  };

  const buttonText = () => {
    if (event.slug === 'royal&rare') {
      return `RSVP`;
    } else if (isEventNotPublished(event)) {
      return `Sale Not Started`;
    } else if (!isEventInFuture(event)) {
      return 'Sale Ended';
    } else if (step === CheckoutFlow.EVENT_VIEW) {
      return areAllTicketsFree(event) ? 'Get Tickets' : 'Buy Tickets';
    } else {
      return 'Cancel';
    }
  };

  const prepareCheckout = async () => {
    if (total > 0) {
      const response = await axios.post('/api/stripe', {
        order: {
          ...order,
          total,
          _id: orderId,
          cart,
          status: 'copped',
        },
        event,
      });

      if (response.data.statusCode === 500) {
        // Please clarify what `logger` refers to and add documentation if necessary
        logger.error(response.data.message);
        return;
      }

      // Please clarify what `setClientSecret` refers to and add documentation if necessary
      const {
        data: { client_secret },
      } = response;
      setClientSecret(client_secret);

      // Please be consistent with return statements in the function
      return client_secret;
    }
  };

  const onShare = () => {
    if (navigator?.share) {
      //Web Share API
      navigator
        .share({
          title: `WhatsTba - ${event?.name}`,
          url: `https://www.whatstba.com/e/${event?.slug}`,
        })
        .then(() => {
          console.log('Thanks for sharing!');
        })
        .catch(console.error);
    } else {
      //Fallback
      setShowPageLink(true);
    }
  };
  const getApplePayOrder = ({}) => {
    return createOrder({});
  };
  const handleCheckout = async (xtraFields) => {
    setExtraFields(xtraFields);
    try {
      setMode(CheckoutFlow.PROCESSING);

      if (waitlist) {
        const waitEntry = {
          _id: customId({
            eventId: event._id,
            firstName,
            lastName,
            orderDate: new Date(),
          }),
          firstName,
          lastName,
          emailAddress,
          waitDate: new Date(),
          updatedAt: new Date(),
          phoneNumber,
          eventId: event._id,
          cleared: false,
          status: 'pending',
        };
        await axios.post('/api/joinWaitlist', {
          waitEntry,
          event,
        });
        setMode(CheckoutFlow.CONFIRMATION);
      } else {
        const newOrder = createOrder(xtraFields);
        if (total === 0) {
          await axios.post('/api/ticket', {
            order: {
              ...newOrder,
              status: (offerBased && !autoAccept) || event.approvalNeeded ? 'pending' : 'copped',
            },
            event,
          });
          purchaseEvent({
            first_name: newOrder.firstName,
            last_name: newOrder.lastName,
            postal_code: newOrder.zipCode,
            affiliation: event.name,
            tax: 0,
            email_address: newOrder.emailAddress,
            value: getTotalOrderRev([newOrder]),
            cart: newOrder.cart,
          });
          setMode(CheckoutFlow.CONFIRMATION);
        } else {
          setMode(CheckoutFlow.PAYMENT_CHECKOUT);
        }
      }
    } catch (error) {
      // handle errors gracefully
      logger.error('Ticket creation error on purchase', error);
      setMode(CheckoutFlow.ERROR);
    }
  };

  const finalizeOrder = () => {
    setOfferCode(offerNotFound ? '' : localOfferCode);
    // scroll();
    // setMode(CheckoutFlow.USER_CHECKOUT);
  };

  useEffect(() => {
    if (event.ticketTypes) {
      setTicketTypes(event.ticketTypes);
    }
  }, [event]);

  return (
    <main className="">
      {/* <PasswordModal
        isOpen={showPasswordModal}
        closeModal={() => setShowPasswordModal(false)}
        password={event.password}
      /> */}
      <section className="flex flex-col items-center justify-center min-h-screen h-full bg-[url('/static/images/cabo/cabo-bg-mb.jpg')] sm:bg-[url('/static/images/cabo/cabo-hero-bg.png')] bg-cover bg-no-repeat bg-left">
        <div className="flex flex-col items-center h-full min-h-screen py-16 md:py-32 justify-center gap-6">
          <div className="px-6">
            <Image alt="" width={550} height={112} src="/static/images/cabo/beyond-borders-logo-cabo.png" />
            <p className="font-solid-grooves text-white text-3xl text-center">MEMORIAL DAY WEEKEND</p>
          </div>
          <div className="px-6">
            <Image
              alt=""
              width={1300}
              height={694}
              src="/static/images/cabo/cabo-hero-mb.png"
              quality={100}
              className="sm:hidden block"
            />
            <Image
              alt=""
              width={1300}
              height={694}
              src="/static/images/cabo/cabo-hero.png"
              quality={100}
              className="hidden sm:block"
            />
          </div>
          <div className="px-6">
            <div className="flex items-center justify-center text-center gap-2 rounded-full px-8 py-2 border border-[#FFFF4E]">
              <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M12.0024 0.70459L15.3958 9.30881L24 12.7022L15.3958 16.0956L12.0024 24.6999L8.60895 16.0956L0 12.707L8.60422 9.31354L12.0024 0.70459Z"
                  fill="#FFFF4E"
                />
              </svg>

              <span className="text-sm sm:text-xl font-bold text-white">
              DUE TO UNFORESEEN CIRCUMTANSCES THIS BEYOND BORDERS TRIP IS POSTPONED UNTIL FURTHER NOTICE!!
              <br />
              Everyone who has paid will receive an email and get a full refund! We apologize for the inconvenience.
              </span>
              <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M12.0024 0.70459L15.3958 9.30881L24 12.7022L15.3958 16.0956L12.0024 24.6999L8.60895 16.0956L0 12.707L8.60422 9.31354L12.0024 0.70459Z"
                  fill="#FFFF4E"
                />
              </svg>
            </div>
          </div>
        </div>
      </section>
      <section className="   flex justify-center">
        <Image
          alt=""
          width={1440}
          height={108}
          quality={100}
          src="/static/images/cabo/all-inclusive-banner.png"
          className="sm:block hidden"
        />
        <Image
          alt=""
          width={1440}
          height={108}
          quality={100}
          src="/static/images/cabo/all-inclusive-banner-mb.png"
          className="block sm:hidden"
        />
      </section>
      <section className="sm:bg-[url('/static/images/cabo/cabo-section-2.jpg')] bg-[url('/static/images/cabo/cabo-section-2-mb.jpg')]  bg-no-repeat bg-cover min-h-[800px] px-4 ">
        <div className="w-full flex flex-col items-center sm:px-20">
          <div className="mt-16 sm:mt-10">
            <p className="font-solid-grooves text-white text-3xl text-center">MEMORIAL DAY WEEKEND</p>
            <Image width={955} height={130} alt="" src="/static/images/cabo/cabo-title-1.png" />
          </div>
          <div>
            <p className="max-w-[955px] text-white mt-6 text-center sm:text-left sm:font-medium">
              Discover the real Los Cabos with Beyond Borders in San José del Cabo, a serene town known for its charming
              architecture and vibrant dining scene. Just a 20-minute drive away, the lively Cabo San Lucas awaits with
              its exciting nightlife and luxury shopping. Whether you crave relaxation, adventure, or cultural
              experiences, Los Cabos offers an escape for every traveler. From deep-sea fishing and world-class golfing
              to snorkeling and horseback riding, your perfect vacation awaits. Step out and make the most of your
              getaway in this Mexican paradise!
            </p>
          </div>
          {/* <div className="dtc-l v-mid tr-l tc f2-l f3 fw6 mt-12">
            <a
              id="btn-ticket-action"
              ref={myRef}
              data-testid="open-tixs"
              href="https://www.wetravel.com/trips/beyound-borders-j-b-entertainment-cabo-san-lucas-65500526"
              style={{
                boxShadow:
                  '0px 4px 4px 0px rgba(255, 245, 0, 0.16), 0px 0px 22px 0px #FFFF86 inset, 0px 4px 6.3px 0px rgba(255, 255, 255, 0.25) inset',
              }}
              className={`rounded-full border border-[#FFFF4E] bg-gradient-to-b from-[#F819FF] to-[#FFF11F] text-lg sm:text-2xl px-20 py-4 uppercase font-bold mt-12  `}>
              BOOK NOW
            </a>
          </div> */}

          {/* {step < CheckoutFlow.PAYMENT_CHECKOUT && (
      
        )} */}
          <Modal
            isOpen={isModalOpen}
            style={{
              overlay: {
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'rgba(0, 0, 0, 0.75)',
                zIndex: '1000',
              },
              content: {
                position: 'absolute',
                top: '40px',
                left: '50%',
                transform: 'translateX(-50%)',
                border: '1px solid #44403C',
                background: '#292524',
                overflow: 'auto',
                WebkitOverflowScrolling: 'touch',
                borderRadius: '16px',
                outline: 'none',
                padding: '20px',
                maxWidth: '1140px',
                width: '100%',
              },
            }}
            onRequestClose={() => {
              setIsModalOpen(false);
              setMode(CheckoutFlow.EVENT_VIEW);
            }}
            contentLabel="Checkout Modal">
            <div className="flex flex-wrap my-8 justify-between w-100 nr3 mb3 pb3 ">
              <div className=" flex gap-4 max-md:max-w-full max-md:flex-wrap">
                <div className="flex flex-col">
                  <div
                    className={
                      step === CheckoutFlow.TICKET_SELECTION
                        ? 'text-white text-xs font-medium  min-w-[120px]'
                        : 'text-stone-500 text-xs font-medium min-w-[120px]'
                    }>
                    Booking
                  </div>
                  <div
                    className={
                      step === CheckoutFlow.TICKET_SELECTION
                        ? 'bg-white flex shrink-0 h-1 mt-1 rounded-[64px] min-w-[120px] '
                        : 'bg-stone-500 flex shrink-0 h-1 mt-1 rounded-[64px] min-w-[120px]'
                    }
                  />
                </div>
                <div className="flex flex-col">
                  <div
                    className={
                      step === CheckoutFlow.USER_CHECKOUT
                        ? 'text-white text-xs font-medium min-w-[120px]'
                        : 'text-stone-500 text-xs font-medium min-w-[120px]'
                    }>
                    Checkout
                  </div>
                  <div
                    className={
                      step === CheckoutFlow.USER_CHECKOUT
                        ? 'bg-white flex shrink-0 h-1 mt-1 rounded-[64px] min-w-[120px]'
                        : 'bg-stone-500 flex shrink-0 h-1 mt-1 rounded-[64px] min-w-[120px]'
                    }
                  />
                </div>
              </div>
              <div className="self-stretch mt-6 max-md:max-w-full">
                <div className="gap-5 flex max-md:flex-col max-md:items-stretch max-md:gap-0">
                  <div className="flex flex-col items-stretch w-[67%] max-md:w-full max-md:ml-0">
                    {step !== 0 && (
                      <div className=" w-full my-10 md:my-5 py-0 black">
                        <div className="tc z-20">
                          <Reward
                            ref={(ref) => {
                              reward.current = ref;
                            }}
                            config={{ spread: 150, lifetime: 400, elementCount: 350 }}
                            type="confetti"
                          />
                        </div>{' '}
                        <Elements stripe={stripePromise}>
                          <div
                            className={` mx-auto  rounded-md  text-gray-01 w-fit ${classnames({
                              dn: step !== CheckoutFlow.PAYMENT_CHECKOUT,
                            })}`}>
                            <div>
                              <FadeIn>
                                <div className="pl4-ns md:pl-0 ">
                                  <div className="mx-auto text-center text-3xl w-full font-extrabold">Checkout</div>
                                </div>
                              </FadeIn>
                            </div>
                          </div>
                        </Elements>
                      </div>
                    )}
                  </div>
                  <div className="flex flex-col items-stretch w-[33%] ml-5 max-md:w-full max-md:ml-0">
                    <div className="border border-stone-700 flex w-full flex-col mx-auto p-6 rounded-lg">
                      <Carousel withIndicators>
                        {/* {selectedTicketName &&
                        event.ticketTypes[selectedTicketName]?.items?.map((image, index) => (
                          <Carousel.Slide>
                            <img key={index} src={image} alt={`Slide ${index}`} />
                          </Carousel.Slide>
                        ))} */}
                      </Carousel>

                      <div className="border-b-stone-700 flex flex-col mt-6 pb-4 border-b">
                        <div className="text-stone-300 text-base font-medium">MAY 24 2023</div>
                        <div className="text-white text-2xl font-bold">Cabo Takeover</div>
                        <div className="text-stone-300 text-sm">
                          <span>Hosted by </span>
                          <span className="font-bold">TBA</span>
                        </div>
                      </div>
                      <div className="text-white text-base font-medium mt-4">Price Details</div>
                      <div className="justify-between flex-wrap flex gap-5 mt-2">
                        <div className="text-stone-100 text-sm">{`$ x 3 nights`}</div>
                        <div className="text-stone-100 text-sm">{`$${total.toFixed(2)}`}</div>
                      </div>
                      <div className="justify-between flex-wrap flex gap-5 mt-1">
                        <div className="text-stone-100 text-sm">Fees</div>
                        <div className="text-stone-100 text-sm">${localFees.toFixed(2)}</div>
                      </div>
                      <div className="justify-between flex-wrap flex gap-5 mt-1">
                        <div className="text-stone-100 text-sm font-medium">{`Total (${event.currency}) `}</div>
                        <div className="text-stone-100 text-sm font-medium">{`Total (USD)`}</div>
                      </div>
                      <button
                        id="btn-submit"
                        type="submit"
                        onClick={() => {
                          trackAction({
                            action: AMPLITUDE_ACTIONS.CLICK,
                            event: AMPLITUDE_EVENTS.CHECKOUT_PAYMENT,
                          });
                          finalizeOrder();
                          scroll();
                          setMode(CheckoutFlow.USER_CHECKOUT);
                        }}
                        className="text-stone-900 text-lg font-medium justify-center items-center mt-6 px-16 py-2 rounded-full bg-stone-50">
                        Go to Checkout
                      </button>

                      <div className="text-red-300 text-base mt-2.5">Have a code?</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
          <div className="flex items-center justify-center text-center gap-2 rounded-full px-8 py-2 border mt-12 border-[#FFFF4E]">
            <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M8.00158 0.703125L10.2639 6.43927L16 8.70155L10.2639 10.9638L8.00158 16.7L5.7393 10.9638L0 8.7047L5.73615 6.44243L8.00158 0.703125Z"
                fill="#FFFF4E"
              />
            </svg>

            <span className="text-sm sm:text-base font-bold text-white ">
            DUE TO UNFORESEEN CIRCUMTANSCES THIS BEYOND BORDERS TRIP IS POSTPONED UNTIL FURTHER NOTICE!!
              <br />
              Everyone who has paid will receive an email and get a full refund! We apologize for the inconvenience.            </span>
            <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M8.00158 0.703125L10.2639 6.43927L16 8.70155L10.2639 10.9638L8.00158 16.7L5.7393 10.9638L0 8.7047L5.73615 6.44243L8.00158 0.703125Z"
                fill="#FFFF4E"
              />
            </svg>
          </div>
        </div>

        <div className="w-full flex justify-end align-right pt-12 sm:pt-36">
          <Image
            src="/static/images/cabo/cabo-polaroid.png"
            alt=""
            width={1500}
            height={540}
            quality={100}
            className="sm:block hidden"
          />
          <Image
            src="/static/images/cabo/cabo-polaroid-mb.png"
            alt=""
            width={1500}
            height={540}
            quality={100}
            className="block sm:hidden"
          />
        </div>
      </section>
      <section className="bg-[#2B2922] flex justify-center">
        <Image
          alt=""
          width={1440}
          height={108}
          quality={100}
          src="/static/images/cabo/all-inclusive-banner.png"
          className="sm:block hidden"
        />
        <Image
          alt=""
          width={1440}
          height={108}
          quality={100}
          src="/static/images/cabo/all-inclusive-banner-mb.png"
          className="block sm:hidden"
        />
      </section>
      <section className="sm:bg-[url('/static/images/cabo/cabo-section-3.jpg')] bg-[url('/static/images/cabo/cabo-section-3-mb.jpg')] bg-no-repeat bg-cover bg-center min-h-[800px] flex flex-col items-center py-16 ">
        <div className="w-full flex flex-col items-start  max-w-[1200px] px-5">
          <div className="mt-10">
            <Image width={530} height={414} alt="" src="/static/images/cabo/whats-included.png" />
          </div>
        </div>
        <div className="w-full flex justify-end pt-12 sm:pt-36">
          <Image
            src="/static/images/cabo/gallery.png"
            alt=""
            width={1300}
            height={268}
            quality={100}
            className="sm:block hidden"
          />
          <Image
            src="/static/images/cabo/gallery-mb.png"
            alt=""
            width={247}
            height={550}
            quality={100}
            className="block sm:hidden"
          />
        </div>
        <div className="dtc-l v-mid tr-l tc f2-l f3 fw6 mt-12">
          {/* <a
            id="btn-ticket-action"
            ref={myRef}
            data-testid="open-tixs"
            href="https://www.wetravel.com/trips/beyound-borders-j-b-entertainment-cabo-san-lucas-65500526"
            style={{
              boxShadow:
                '0px 4px 4px 0px rgba(255, 245, 0, 0.16), 0px 0px 22px 0px #FFFF86 inset, 0px 4px 6.3px 0px rgba(255, 255, 255, 0.25) inset',
            }}
            className={`rounded-full border border-[#FFFF4E] bg-gradient-to-b from-[#F819FF] to-[#FFF11F] text-lg sm:text-2xl px-20 py-4 uppercase font-bold mt-12`}>
            BOOK NOW
          </a> */}

          <a className="font-solid-grooves white block mv4" href="/refund-policy">
            Refund Policy
          </a>
        </div>
      </section>
    </main>
  );
};
